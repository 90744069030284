<template>
  <div class="detail">
    <div class="detailtop">
      <img src="@/assets/abd/image/male.png" class="imgtouxiang" alt />
    </div>
    <div class="detailContent">
      <div class="content">
        <div class="yanzheng">
          <div class="warn">
            <span>请输入访问密码</span>
          </div>
          <input  type="text" @blur="onBlur" v-model="text" class="pwdtext" placeholder />
          <div class="btn">
            <button @click="submit" class="submit context_bg">提交</button>
          </div>
        </div>
        <div></div>
        <div class="explain">
          <h2 class="footH2">————&nbsp;&nbsp;&nbsp;&nbsp;访问须知&nbsp;&nbsp;&nbsp;&nbsp;————</h2>
          <div class="c-bar">
            <p class="barword">
              <i class="yuandian"></i>为了方便您下次查看，建议您轻点右上角并选择“收藏”，同时请您记住访问密码。
            </p>
          </div>
          <div class="c-bar">
            <p class="barword">
              <i class="yuandian"></i>本报告仅限保单持有人（即该保单的投保人/被保人）本人方可查看，如果您不是保单持有人，请勿访问并立即关闭此页面。
            </p>
          </div>
          <div class="c-bar">
            <p class="barword">
              <i class="yuandian"></i>为了您的信息安全，请您千万不要将本页面及访问密码随意转发给其他人。如果由于您的疏忽导致本报告的相关内容或信息泄漏，所造成的损失均予爱保单平台无关。
            </p>
          </div>
          <div class="c-bar">
            <p class="barword">
              <i class="yuandian"></i>本解析报告仅供理解保险条款所用，具体的保险责任及内容均以正式的保险合同为准。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
import { Toast } from 'mand-mobile'
export default {
  name: 'sharereport',
  data () {
    return {
      text: ''
    }
  },
  mounted () {

  },
  methods: {
    submit () {
      let result = JSON.parse(Base64.decode(this.$route.query.t))
      if (result.verify !== this.text) {
        Toast.info('验证码错误!')
      } else {
        result.path.query.code = this.text
        result.path.query.sharetype = '00'
        this.$router.push({ path: result.path.p, query: result.path.query })
      }
    },
    onBlur (val, $event) {
      window.scroll(0, 0)
    }
  }
}
</script>

<style scoped>
.btn {
  text-align: center;
}
.detailtop {
  width: 100%;
  height: 360px;
  background-image: url("~@/assets/abd/image/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 100px;
}
.imgtouxiang {
  width: 120px;
  height: 120px;
}
.name {
  display: block;
  width: 100%;
  height: 80px;
  text-align: center;
  font-size: 32px;
  /* font-weight: 600; */
  margin: 60px auto 0;
  letter-spacing: 2px;
}
.detailNav {
  position: absolute;
  top: -50%;
  left: 50%;
  margin-left: -40%;
  margin-top: 300px;
  width: 80%;
  height: 330px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 2px 2px 8px #ccc;
  padding: 30px;
}
.top_top {
  font-size: 28px;
  /* font-weight: 600; */
  letter-spacing: 2px;
}
.top_word {
  margin-top: 80px;
  font-size: 24px;
}
.analysis {
  padding: 0 30px 30px;
}
.tou {
  position: relative;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  margin: 20px 0 0;
}
.c-bar {
  margin-top: 30px;
  letter-spacing: 1px;
  background-color: #fff;
}
.explain {
  padding: 50px;
  margin-bottom: 100px;
}
.explain > h5 {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 28px;
  letter-spacing: 1px;
}
.explain > h4 {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 26px;
  /* font-weight: normal; */
  letter-spacing: 1px;
}
.barline {
  border-bottom: 2px dashed #aaa;
  line-height: 60px;
}
.barword {
  margin-top: 20px;
  font-size: 28px;
  letter-spacing: 3px;
  color: #bfbfbf;
}
.footH2 {
  font-size: 28px;
  text-align: center;
  color: #bfbfbf;
}
.topName {
  font-size: 28px;
  /* font-weight: bold; */
}
.warn {
  color: #b5b5b5;
  font-size: 28px;
  padding-bottom: 53px;
}
.pwdtext {
  width: 100%;
  height: 70px;
  text-align: left;
  border-radius: 10px;
  border-bottom: 1px solid #ebebeb;
}
.submit {
  background-color: #036eb5;
  color: #fff;
  width: 80%;
  height: 70px;
  text-align: center;
  border-radius: 10px;
  margin: 20px 0;
}
.yanzheng {
  padding: 30px;
  background: #fff;
  box-shadow: 0 10px 30px #e2e8f6;
}
.yanzheng::placeholder {
  color: #aaa;
}
.footerimg {
  width: 100px;
  display: block;
  margin: 0 auto;
}
.footerP {
  width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
}
.yuandian {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #bfbfbf;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
